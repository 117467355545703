@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

.quantity-link {
  flex: 1;
  justify-content: flex-start;
  /* Need this to override style in base-buttons.scss*/
  max-width: none;
  @include util.media(sizes.$mq-mobile) {
    width: auto;
    ~ .btn {
      margin-top: auto;
    }
  }
}
