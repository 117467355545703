@use '../../../styles/vars.scss' as vars;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.rotate-drawer {
  .header-wrapper {
    width: 100%;
    max-width: var(--scaleDrawerMaxWidth, 330px);
    margin: 0 auto;
    text-align: center;

    @media (max-width: vars.$TabletSize) {
      margin: 0;
    }
  }

  .footer-wrapper {
    @extend .header-wrapper;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: sizes.$space-16 0;
  }

  .footer-buttons {
    margin: 5px;
    width: auto;
  }

  .rotation-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px auto 24px auto;

    .value {
      &::after {
        content: '';
      }
      &.degree {
        &::after {
          content: '\00B0';
        }
      }

      &.percent {
        &::after {
          content: '%';
        }
      }
    }
  }

  .header-wrapper {
    div:nth-child(2) > div:first-child {
      @media (min-width: 768px) and (max-width: 1336px) {
        position: absolute !important;
        background: #562b9b !important;
        margin-top: 0.6875rem;
      }
    }
  }

  div:nth-child(2) > div:first-child {
    @media (min-width: 768px) and (max-width: 1336px) {
      border-top-right-radius: 0;
      position: static;
      background: transparent;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
