@use '../../../styles/vars.scss' as vars;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

:root {
  --orderDrawerMaxWidth: #{util.rem(330px)};
  --orderDrawerRangeLabelPadding: 0 #{sizes.$space-8} #{sizes.$space-8};
}

@mixin drawer-content {
  width: 100%;
  max-width: var(--orderDrawerMaxWidth);
  margin: 0 auto;
}

.order-drawer {
  background-color: blue;
  .header-wrapper {
    @include drawer-content;
    padding-top: sizes.$space-24;

    .range-label {
      display: flex;
      justify-content: space-between;
      padding: var(--orderDrawerRangeLabelPadding);
    }

    .order-range {
      label {
        // Screen Reader Text
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        width: 1px;
        overflow: hidden;
        position: absolute;
      }
    }
  }

  .footer-wrapper {
    @include drawer-content;
    max-width: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
  }

  .footer-cancel-button {
    margin: util.rem(5px);

    @media (max-width: vars.$TabletSize) {
      width: 100%;
    }
  }
  .footer-primary-button {
    margin: util.rem(5px);
    @media (max-width: vars.$TabletSize) {
      width: 100%;
    }
  }

  div:nth-child(2) > div:first-child {
    @media (min-width: 768px) and (max-width: 1336px) {
      border-top-right-radius: 0;
      position: static;
      background: transparent;
      padding: 0;
      margin: auto;
    }
  }
}
