@use '../../../styles/vars.scss' as vars;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '../../colors/ce-shade-colors' as shade;
@use '../../colors/ce-colors' as primary;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

@each $name, $color in primary.$ce-colors {
  .shade-color-#{$name} {
    background-color: $color;
  }
}

@each $name, $color in shade.$ce-shade-colors {
  .shade-color-#{$name} {
    background-color: #{$color};
    text-align: center;
  }
}

.color-palette-drawer {
  z-index: vars.$zIndexDrawer;

  button {
    border: none !important;
  }

  .header {
    font-size: sizes.$space-24;
    line-height: sizes.$space-32;
    color: colors.$dark-gray;
  }

  .footer-wrapper {
    display: flex;
    gap: util.rem(18px);
    flex-wrap: wrap;
    padding: util.rem(20px) util.rem(12px);
    margin: util.rem(0px) util.rem(-20px);
    justify-content: flex-start;

    .color-button {
      width: util.rem(40px);
      height: util.rem(40px);
      border-radius: 50%;
      margin-top: util.rem(0px);
      cursor: pointer;
      box-shadow: 3px 3px 3px 3px colors.$light-gray;
      transition: transform 0.3s ease, border 0.3s ease;
      position: relative;

      &:hover,
      &:focus {
        border-width: util.rem(3px);
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 100%;
        border: 2px solid #e5e5e5;
        width: 54px;
        height: 54px;
        top: -7px;
        left: -7px;
      }
    }
  }

  .button-group {
    flex-flow: row wrap;
    padding: 0;
    justify-content: flex-start;
    gap: util.rem(30px);
    padding-left: 10px;
  }
}
