// Primary colors
$ce-purple: #562b9b;
$ce-ochre: #e3ac41;
$ce-pink: #ed3e6c;
$ce-gradient: linear-gradient(270deg, #5b2b9b 8.07%, #ed3e76 94.96%);

// Secondary colors
$ce-blue: #7684c8;
$ce-off-white: #eeeae6;
$ce-background: #faf9f8;

// 10% on white
$ce-10-purple: #eeebf4;
$ce-10-ochre: #fbf7ee;
$ce-10-pink: #fbedf0;

// Neutral colors
$ce-light-gray: #e5e5e5;
$ce-medium-gray: #7f8386;
$ce-anthracite: #333333;

// State colors
$ce-positive: #01982b;
$ce-negative: #b61f3f;

// Background colors
$ce-7-ochre: #fcf7ee;
$ce-20-ochre: #f8eedd;

// Default colors
$ce-default-black: #000000;
$ce-default-blue: #1319c7;
$ce-default-green: #55d602;
$ce-default-red: #cd0a1b;
$ce-default-magenta: #bc1775;
$ce-default-purple: #ae18f2;

$ce-colors: (
  // Primary colors
  'ce-purple': $ce-purple,
  'ce-ochre': $ce-ochre,
  'ce-pink': $ce-pink,
  'ce-gradient': $ce-gradient,

  // Secondary colors
  'ce-blue': $ce-blue,
  'ce-off-white': $ce-off-white,
  'ce-background': $ce-background,

  // 10% on white
  'ce-10-purple': $ce-10-purple,
  'ce-10-ochre': $ce-10-ochre,
  'ce-10-pink': $ce-10-pink,

  // Neutral colors
  'ce-light-gray': $ce-light-gray,
  'ce-medium-gray': $ce-medium-gray,
  'ce-anthracite': $ce-anthracite,

  // State colors
  'ce-positive': $ce-positive,
  'ce-negative': $ce-negative,

  // Background colors
  'ce-7-ochre': $ce-7-ochre,
  'ce-20-ochre': $ce-20-ochre,

  // Default colors
  'ce-default-black': $ce-default-black,
  'ce-default-blue': $ce-default-blue,
  'ce-default-green': $ce-default-green,
  'ce-default-red': $ce-default-red,
  'ce-default-magenta': $ce-default-magenta,
  'ce-default-purple': $ce-default-purple
);
