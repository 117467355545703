@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

.screen-loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: colors.$white;
  gap: 24px;
  flex-direction: column;
  z-index: 10000;

  &.drawer {
    top: 90px;
  }

  .screen-loader-content {
    max-width: 500px;
    text-align: center;

    @include util.media(sizes.$mq-mobile) {
      max-width: unset;
      padding: 0 24px;
    }
  }
}

.animated-crown {
  width: 180px;
  height: 180px;

  @include util.media(sizes.$mq-mobile) {
    width: 120px;
    height: 120px;
  }
}
