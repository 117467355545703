@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.address-confirmation-dialog {
  .suggested-address-content {
    padding-right: sizes.$space-16;

    .suggested-address-description {
      margin-bottom: sizes.$space-16;

      .suggested-address-title {
        color: colors.$dark-gray;
        display: block;
        text-transform: capitalize;
      }

      .suggested-address-body {
        color: colors.$dark-gray;
        display: block;
      }
    }
  }

  .dialog-content {
    padding: 0;
  }

  .address-options {
    display: flex;
    justify-content: space-between;
  }

  .bottom-content {
    .address-box {
      padding: sizes.$space-16;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 3px;
      background-color: colors.$light-gray;
      margin: sizes.$space-16;
      margin-left: 0;
      .address-header {
        margin-bottom: sizes.$space-8;
        display: block;
      }

      .address {
        margin-bottom: sizes.$space-8;
      }

      .edit-button {
        width: auto;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-actions {
    display: flex;
    padding: sizes.$space-16 sizes.$space-16 0 0;
    justify-content: space-between;
    align-items: center;

    .address-confirmation-steps {
      min-width: fit-content;
      margin-right: util.rem(20px);
      padding: 0 util.rem(8px);
    }
  }
}
