@use 'src/styles/vars' as vars;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.summary-drawer {
  z-index: vars.$IndexSidePanel;

  .summary-close-icon {
    padding-right: sizes.$space-24;
  }

  .header-wrapper {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    padding-left: sizes.$space-24;
    gap: sizes.$space-16;
    color: colors.$primary-font-color;

    .draft-name-container {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .draft-name-form {
      display: flex;
      width: 100%;
      gap: sizes.$space-16;
      align-items: baseline;

      .summary-input {
        flex-grow: 1;
        width: 100%;
      }
    }
  }
}
