@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '../../styles/vars.scss' as vars;

:root {
  --loaderBackground: #{colors.$editor-background};
}

.loader-background {
  position: absolute;
  inset: 0;
  background-color: var(--loaderBackground);

  padding: sizes.$space-40;
  display: none;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: vars.$IndexSpinner;
  }

  .loader-wrapper {
    position: relative;
    width: 100%;

    .loader-indicator {
      position: relative;
      height: 75px;
    }
  }
  .loader-title {
    padding-top: sizes.$space-8;
    text-align: center;
    color: colors.$dark-gray;
  }
}
