@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

.drawer {
  z-index: 10;

  & > :last-child {
    max-width: 450px !important;
  }

  .headline {
    color: colors.$dark-gray;
    font-size: util.rem(22px);
    line-height: util.rem(28px);
  }

  .contact-row {
    display: flex;

    .name {
      flex-basis: 80%;
    }

    button {
      justify-content: flex-end;
      flex-basis: 20%;
    }
  }

  .icon {
    z-index: 10;
    :before {
      color: var(--icon-color);
    }
  }

  .radio-card input[type='radio'],
  .radio-card input[type='radio']:hover,
  .radio-card input[type='radio']:checked {
    border: none;
    border-bottom: 1px solid colors.$medium-gray;
    border-radius: 0;
  }

  .radio-card > span {
    align-self: flex-start;
  }

  .radio-card label {
    display: block;
  }

  .footer-content-button {
    width: 100%;
    max-width: none;
  }

  .empty-message {
    display: block;
    padding-right: 20%;
  }

  .hidden {
    opacity: 0;
    pointer-events: none;
  }
}
