//@use './styles/shared.scss';
@use './styles/vars.scss' as vars;
@use './styles/reset.scss';
@use './styles/forms.scss';

* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.app {
  background-color: var(--appBackground, #e4e2ed);
  background-repeat: repeat;
  height: 100%;
  max-width: 100vw;
}

canvas {
  touch-action: manipulation;
}

textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}

[data-testid='toast'],
[data-testid='toast'] *,
[data-testid='dialog-overlay'],
[data-testid='dialog-overlay-child'],
[data-testid='dialog-scroll'],
[data-testid='toast-dialog-content'],
[data-testid='toast'] button,
[data-testid='toast'] span,
[data-testid='toast'] div {
  outline: none !important;
}
