@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
// @use '~@hallmark/web.core.typography.typography/typography.module.scss' as typography;

.address-form-wrapper {
  background-color: colors.$white;
  border-radius: sizes.$space-8;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;

  @include util.media(sizes.$mq-tablet-up) {
    max-width: util.rem(457px);
  }

  @include util.media(sizes.$mq-mobile-xl) {
    background-color: transparent;
  }

  .address-form {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .address-form-content {
      padding: util.rem(20px);
      flex-grow: 1;

      @include util.media(sizes.$mq-mobile-xl) {
        background-color: colors.$white;
        flex-grow: 0;
        border-radius: sizes.$space-8;
      }
      .title {
        // @include util.font-sans(typography.$beam-114, typography.$beam-vf, 'sans-serif', 114, 700);
        letter-spacing: 0;
        line-height: util.rem(26px);
        color: colors.$dark-gray;
      }

      .addressing {
        .title {
          letter-spacing: 0;
          line-height: util.rem(26px);
          color: colors.$dark-gray;
          margin-bottom: sizes.$space-8;
        }
        .radio-button {
          margin-left: 0;
        }
      }
    }

    .login-button {
      border: 0;
      padding: 0;
      width: unset;
      @include util.media(sizes.$mq-tablet-up) {
        margin: sizes.$space-12 0;
      }
    }

    .text-link {
      justify-content: flex-start;
      text-align: start;
      margin-top: sizes.$space-12;

      &.collapse-trigger {
        margin-top: util.rem(20px);

        .collapsed::before {
          transform: rotate(180deg);
        }
      }
    }

    .collapse-panel {
      padding-bottom: 0;
    }

    .radio-card {
      margin: 1rem 0;
    }

    .invalid-field {
      color: #c54c4c;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0;
      margin-top: util.rem(6px);
      padding-left: util.rem(10px);
      font-family: 'BeamNewHMK-VF', 'sans-serif';
      font-variation-settings: 'wght' 114, 'wdth' 700;
    }

    .form-field {
      margin: sizes.$space-16 0 0;
      width: 100%;

      &:first-of-type {
        margin-top: sizes.$space-8;
      }
    }
  }
}

.checkbox {
  margin: sizes.$space-16 0 0;
}

.segmented-control {
  width: 100%;
  margin-bottom: sizes.$space-12;
  margin-top: sizes.$space-12;
}

.hide-address {
  overflow: hidden;
  height: 0;
}
