@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '../../styles/vars.scss' as vars;
.editor {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  @media (max-width: 1140px) {
    display: flex;
    flex-direction: column;
  }

  .workspace-panel {
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction: column;
    transition: all 0.8s ease-in;
    padding: 40px 0 24px 0;

    @media (max-width: 1140px) {
      padding: 12px 0 24px 0;
    }
  }

  .footer,
  .footer-variant {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-select-bar {
    grid-column: 2;
    place-self: center;
    overflow-y: hidden;
  }

  .address-button {
    grid-column: 3;
    place-self: center end;
  }

  .drawer {
    z-index: vars.$zIndexDrawer;
  }

  @media (max-width: 1140px) {
    .footer {
      display: flex;
      justify-content: space-between;
    }

    .footer-variant {
      display: flex;
      justify-content: center;
      max-height: 100px;
    }

    .address-button {
      width: 33%;
    }
  }
}
