@use 'src/styles/vars' as vars;

.edit-button {
  text-transform: none !important;
  @media (max-width: vars.$PhoneSize) {
    width: auto;

    span {
      font-weight: 600;
    }
  }
}

.button {
  text-transform: none !important;

  span {
    text-transform: none !important;
  }
}
