@use 'src/styles/vars' as vars;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

.layout {
  width: 100vw;
  width: 100dvw;
  display: grid;
  height: 100vh;
  height: 100dvh;
  grid-template-columns: vars.$ToolBarWidth calc(100% - vars.$ToolBarWidth);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'toolbar header'
    'toolbar content';

  @media (max-width: vars.$Desktop) {
    &:not(.no-toolbar) {
      grid-template-columns: 100%;
      grid-template-rows: auto 1fr vars.$ToolBarHeightMobile;
      grid-template-areas:
        'header'
        'content'
        'toolbar';
    }
  }

  @media (min-width: 767px) and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
  }

  .content {
    grid-area: content;

    @media (min-width: 767px) and (max-width: 1025px) {
      height: calc(100vh - 88px - 147px);
    }
  }

  &.no-toolbar {
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'header'
      'content';
  }

  &.editor-menu {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas:
      'header'
      'content';

    @media (max-width: vars.$Desktop) {
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        'header'
        'content'
        'menu';
    }
  }
}

.layout .btn {
  padding: 0 1.5rem;
}
