.wam-color-drawer {
  button {
    max-width: none;
  }
}

.text-option-drawer-container > div:nth-child(2) > div:first-child > div:first-child {
  scrollbar-width: auto;
  justify-content: unset !important;

  @media only screen and (min-width: 766px) and (max-width: 1439px) {
  }
}

.text-option-drawer-container > div:nth-child(2) > div:first-child > div:first-child span {
  @media only screen and (max-width: 1025px) {
    font-weight: 500 !important;
    text-transform: unset !important;
  }
}

.text-option-drawer-container {
  @media only screen and (min-width: 766px) and (max-width: 1025px) {
    padding: 20px !important;
  }
}

.font-drawer {
  @media (min-width: 768px) and (max-width: 1336px) {
    padding: 2.5rem 1.5rem 1.5rem 1.5rem !important;
  }

  div:nth-child(2) > div:first-child {
    @media (min-width: 768px) and (max-width: 1336px) {
      border-top-right-radius: 0;
      position: static;
      background: transparent;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  div:first-child > div:first-child > div:first-child {
    @media (min-width: 768px) and (max-width: 1336px) {
      width: 100%;
      padding: 10px 0;
    }
  }
}

.size-drawer {
  z-index: 10;

  div:nth-child(2) > div:first-child {
    @media (min-width: 768px) and (max-width: 1336px) {
      border-top-right-radius: 0;
      position: static;
      background: transparent;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
