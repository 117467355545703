@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use 'src/styles/vars' as vars;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;

:root {
  --print-on-demand-header-padding: 14px 40px;
  --print-on-demand-header-padding-mobile: 12px;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  background: transparent;
  justify-content: space-between;
  align-items: center;
  padding: var(--print-on-demand-header-padding);
  grid-area: header;
  z-index: 10;

  @media (max-width: vars.$Desktop) {
    background-color: var(--headerBackgroundMobile);
    padding: var(--print-on-demand-header-padding-mobile);
  }

  .logo {
    cursor: pointer;
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    top: 53px;
    align-items: center;
    padding: 0;
    border: 0;
    width: max-content;
    height: 40px;
    /* Need this to override style in base-buttons.scss*/
    max-width: none;
    background: transparent;

    &:hover {
      box-shadow: none;
    }

    @media (max-width: vars.$Desktop) {
      display: none;
    }
  }

  .crown {
    width: auto;
    flex: 1;
    text-align: center;
    align-items: center;
    padding: 0;
    border: 0;
    /* Need this to override style in base-buttons.scss*/
    max-width: none;
    background: transparent;
    @media (max-width: vars.$Desktop) {
      margin-top: 0;
    }

    span {
      height: util.rem(29px);
    }

    &:hover {
      box-shadow: none;
    }
  }
  .close-button {
    /* Need this to override style in base-buttons.scss*/
    display: flex;
    max-width: none;
    margin: 0;
    width: auto;
    :before {
      color: var(--icon-color);
    }

    &:hover {
      :before {
        color: var(--primary-button-hover-background-color);
      }
    }
  }

  .save-project {
    display: flex;
    flex: 1;
  }

  .save-project-modern {
    display: flex;
    gap: 24px;
    align-items: center;

    @media (max-width: vars.$Desktop) {
      gap: 8px;

      .address-button {
        padding: 8px 16px;
        max-width: max-content;
      }

      // remove after DS updates
      button,
      div {
        height: 40px;
      }
    }
  }

  .back-button {
    max-width: max-content;

    // remove after DS updates
    span {
      font-weight: 500;
      text-transform: none !important;
    }
  }

  .quantity-link-placeholder {
    flex: 1;
  }
}
