@use '../../../styles/vars.scss' as vars;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

@mixin drawer-content {
  width: 100%;
  max-width: var(--scaleDrawerMaxWidth, 330px);
  margin: 0 auto;
}

.scale-drawer {
  label {
    display: none !important;
  }

  .header-wrapper {
    @include drawer-content;
    text-align: center;
  }

  .footer-wrapper {
    @include drawer-content;
    display: flex;
    justify-content: space-between;
  }

  .footer-buttons {
    margin: 5px;
  }

  .scale-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px auto 24px auto;

    .value {
      &::after {
        content: '%';
      }
    }
  }

  div:nth-child(2) > div:first-child {
    @media (min-width: 768px) and (max-width: 1336px) {
      border-top-right-radius: 0;
      position: static;
      background: transparent;
      padding: 1rem 0;
      margin: auto;
    }
  }
}
