@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '../../styles/vars.scss' as vars;

:root {
  --carouselImageDotContainerPadding: 0;
}

.carousel-image-dot-container {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  font-family: inherit;
  z-index: 11;
  padding: 0;

  @media (max-width: vars.$Desktop) {
    width: 100%;
    overflow: auto;
    justify-content: center;
    padding: 0 0 10px 0;
  }

  .active-image-dot-button {
    border: none;
    img {
      border: 3px solid var(--border-color);
    }
  }

  .image-dot-button {
    background: none;
    width: auto;
    border-radius: 0;

    @media (max-width: 1025px) {
      margin: 0 !important;
    }

    div {
      color: colors.$dark-gray;
      font-size: util.rem(12px);
    }
    & span > div {
      margin-bottom: 10px;
      text-transform: capitalize;
    }
    img {
      width: auto;
      margin: auto;
    }
  }
}
